/* eslint-disable no-magic-numbers */

/**
 * windowResize handler that extends the window.resize listener for multiple methods
 * and debounces the window.onresize event by 100ms
 *
 * @property {number} timer Time in millisecs used to debounce the window.onresize event - defaults to 200
 */
let windowResizeTimer;
let resizeTimer;
let initialised = false;
const notifiers = [];

/**
 *
 * @param timer
 */
function windowResize(timer) {
    windowResizeTimer = timer || 200;
}

export const addResizeNotifier = (notifier) => {
    if (notifiers.indexOf(notifier) < 0) {
        notifiers.push(notifier);
    }
    if (!initialised) {
        init();
    }
};

export const removeResizeNotifier = (notifier) => {
    const index = notifiers.indexOf(notifier);
    if (index) {
        notifiers.splice(index, 1);
    }
};

/**
 *
 */
function notify() {
    notifiers.forEach((notifier) => {
        notifier.method(notifier.args);
    });
}

/**
 *
 */
function init() {
    window.onresize = function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            notify();
        }, windowResizeTimer);
    };

    initialised = true;
}

windowResize(100);
