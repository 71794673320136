/* eslint-disable no-unused-vars */
const OBSERVER_CONFIG = {
    root: null, // default to browser viewport
    rootMargin: '100px',
    threshold: [0.1, 0.9] // eslint-disable-line no-magic-numbers
};

let widgetsToInitialize = {};
let observer;

/**
 * @constant {object} widgets - keeps track of all instances of widgets
 */
let widgets = {};

/**
 * Adds a single instance of the given widget (will replace any existing instances)
 *
 * @param {string} widgetMarker the name of the data-buildlab-widget attribute to query for in order to find the widget
 * @param {object} widgetConstructor optional, the class to create a new instance of for the
 *  widget. If this is not set, it will be derived from the widgetMarker
 * @param element
 */
function addWidget(widgetMarker, element = document) {
    let widgetContainer = element.querySelector(
        '[data-buildlab-widget="' + widgetMarker + '"]'
    );

    if (widgetContainer && widgetContainer.dataset.initialized !== 'true') {
        createWidget(widgetMarker, widgetContainer);
    }
}

/**
 * Creates an array of widgets for every instance of the HTML marker that is found on the page
 *
 * @param {string} widgetMarker the name of the data-buildlab-widget attribute to query for in order to find the widgets
 * @param {object} widgetConstructor optional, the class to create a new instance of for the
 *  widget. If this is not set, it will be derived from the widgetMarker
 * @param {HTMLElement} element optional override (instead of querying the whole document)
 */
function addMultipleWidgetsByName(widgetMarker, element = document) {
    let widgetContainers = element.querySelectorAll(
        '[data-buildlab-widget="' + widgetMarker + '"]'
    );
    for (let i = 0; i < widgetContainers.length; i++) {
        createWidget(widgetMarker, widgetContainers[i]);
    }
}

/**
 * Looks for all data-buildlab-widget attributes in the given DOM element (or document) and creates
 * instances for each of these if they haven't been initialised already. Initialised widgets
 * will have data-initialised="true" on their data-buildlab-widget DOM element.
 *
 * A data-constructor can be set to define the name of the constructor within the 'app'
 * namespace (e.g., app.MatchCentreWidget would be data-constructor="MatchCentreWidget". If
 * this is not set, it will be derived from the data-buildlab-widget attribute:
 * data-buildlab-widget="match-centre" becomes MatchCentreWidget
 *
 * @param {HTMLElement} element optional override (instead of querying the whole document)
 */
function addAllWidgets(element = document) {
    let widgetContainers = element.querySelectorAll('[data-buildlab-widget]');

    for (let i = 0; i < widgetContainers.length; i++) {
        let widgetMarker = widgetContainers[i].getAttribute(
            'data-buildlab-widget'
        );
        createWidget(widgetMarker, widgetContainers[i]);
    }
}

/**
 * Creates an Intersection observer
 *
 * @returns {boolean} - whether an IntersectionObserver has been created
 */
const createObserver = () => {
    if (typeof observer === 'undefined') {
        if (typeof IntersectionObserver !== 'undefined') {
            observer = new IntersectionObserver((observedItems) => {
                [].slice.call(observedItems).forEach((observedItem) => {
                    const element = observedItem.target;
                    // if the widget should be initialized
                    if (
                        observedItem.isIntersecting &&
                        element.dataset.initialized !== 'true'
                    ) {
                        initializeWidget(element);
                    }
                });
            }, OBSERVER_CONFIG);
        } else {
            // if there's no IntersectionObserver, just create the widget
            return false;
        }
    }
    return true;
};

const initializeWidget = (widgetContainer) => {
    const widgetMarker =
        widgetsToInitialize[widgetContainer.dataset.observableId];

    if (widgetMarker) {
        importAndInitWidget(widgetMarker, widgetContainer);
        widgetContainer.setAttribute('data-initialised', true);

        delete widgetsToInitialize[widgetContainer.dataset.observableId];
        if (typeof observer !== 'undefined') {
            observer.unobserve(widgetContainer);
        }
    } else {
        console.warn('Observing unexpected widget', widgetContainer);
    }
};

/**
 * Adds an instance of a given widget to the widget array stored in the app
 *
 * @param {string}     widgetMarker      - the name of the data-widget attribute to query for in order to find the widgets
 * @param {HTMLElement} widgetContainer   - the DOM element for the widget
 */
const createWidget = (widgetMarker, widgetContainer) => {
    if (widgetContainer.dataset.initialised !== 'true') {
        if (!widgets[widgetMarker]) {
            widgets[widgetMarker] = [];
        }

        if (
            widgetContainer.dataset.initWhenVisible === 'true' &&
            createObserver()
        ) {
            const observableId = getUniqueId(widgetMarker);
            widgetsToInitialize[observableId] = widgetMarker;
            widgetContainer.dataset.observableId = observableId;
            observer.observe(widgetContainer);
        } else {
            importAndInitWidget(widgetMarker, widgetContainer);
            widgetContainer.setAttribute('data-initialised', true);
        }
    }
};

/**
 * Switch case for each widget js file found in `sites/widgets/**\/js/*.js`
 * (top level folder only). Each of these top level JS files should export a
 * default class that is the widget. This switch statement is populed via a gulp
 * task just before the build starts. See gulp-script.js:141 (getWidgetsImportSwitch) for what this looks like.
 *
 * @param {string} widgetMarker - The marker for the widget, should be <foldername>/<filename>
 * @param {HTMLElement} widgetContainer - The container element that the widget data attrbiute was found on
 */
// eslint-disable-next-line no-unused-vars
function importAndInitWidget(widgetMarker, widgetContainer) {
    switch (widgetMarker) {
        //eslint-disable
        //<WIDGET_IMPORT_SWITCH>
        //eslint-enable
        default:
            break;
    }
}

/**
 * Used inside the promise returned after a widget is dynamically
 * imported. Gets the constructor from the class, the marker string and the container.
 *
 * @param {any} WidgetConstructor - The widget JS class to create a new instance off
 * @param {string} widgetMarker - The marker for the widget, should be <foldername>/<filename>
 * @param {HTMLElement} widgetContainer - The container element that the widget data attrbiute was found on
 */
function onWidgetImport(WidgetConstructor, widgetMarker, widgetContainer) {
    if (widgetMarker !== 'search-results') {
        const widgetInstance = new WidgetConstructor(widgetContainer);
        widgets[widgetMarker].push(widgetInstance);
    }
}

/**
 * Fires whenever there is an issue with importing and starting a new widget
 * instance.
 *
 * @param {string} widgetMarker - The marker for the widget, should be <foldername>/<filename>
 * @param {Error} error - The error passed to the catch
 */
function onWidgetInitError(widgetMarker, error) {
    console.error(
        `[WidgetInitialiser] There was a problem initialising widget: ${widgetMarker}. Error: `,
        error
    );
}

/**
 * Generates a unique ID for a widget based on its marker and the timestamp
 *
 * @param  {string} widgetMarker - the marker from the data-widget attr
 * @returns {string}              - unique string
 */
const getUniqueId = (widgetMarker) =>
    `${widgetMarker}_${Date.now()}_${Math.random()}`;

/**
 * Clear out all instances of widgets after a page transition and call their
 * destroy methods, if they have one
 */
function destroyAllWidgets() {
    for (let widgetName in widgets) {
        if (Object.prototype.hasOwnProperty.call(widgets, widgetName)) {
            for (
                let i = 0, length = widgets[widgetName].length;
                i < length;
                i++
            ) {
                const instance = widgets[widgetName][i];
                if (typeof instance.destroy === 'function') {
                    instance.destroy();
                }
            }
            widgets[widgetName] = [];
        }
    }
}

/**
 * Subscribes to custom event fired by Page Builder script - visual-editor-embed
 * Whenever a widget is added or updated in the page builder it is re-rendered
 * so we want to re-initialise the script for that widget
 */
function subscribePageBuilder() {
    document.body.addEventListener('page-builder/widget-init', (event) => {
        const { widgetMarker, widgetContainer } = event.detail;
        createWidget(widgetMarker, widgetContainer);

        // Trigger lazy load script to initialise any images in the rendered widget
        new LazyLoad(widgetContainer);
    });
}

export {
    addWidget,
    addMultipleWidgetsByName,
    addAllWidgets,
    destroyAllWidgets,
    subscribePageBuilder
};
