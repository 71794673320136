import { setViewportHeightUnit } from './setViewportHeightUnit';
import { addAllWidgets, subscribePageBuilder } from './widgetInitialiser';
import { setupLazyImages } from 'common/content/lazy-load';
import { addResizeNotifier } from 'common/helpers/window-resize';

/**
 * Fires as soon as the DOM is ready, put anything you want to happen
 * IMMEDIATELY and on every page, here.
 */
function domReady() {
    addAllWidgets();
    setupLazyImages();
    addResizeNotifier({ method: setViewportHeightUnit });
    subscribePageBuilder();
}

switch (document.readyState) {
    case 'interactive':
    case 'complete':
        domReady();
        break;
    default:
        document.addEventListener('DOMContentLoaded', () => {
            domReady();
        });
}
